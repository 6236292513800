import React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import { Section, SectionWrapper, SubTitle, Text } from "../../core/commonExports"
import { Button } from "../../core/Button"
import { OnDesktop, OnMobile } from "../../DesktopMobileComps"

const InfoCard = ({title, type, mobileBg, fixedWidth=false}) => { 
  let icon = null
  let desc = null

  if (type === "insurance") {
    icon = (
      <InfoCardImgContainer>
        <StaticImage 
          src="../../../assets/images/campaigns/why-safety-plan/theft.webp"
          alt="theft"
          width={191}
          height={190}
        />
      </InfoCardImgContainer>
    )
    desc = (
      <InfoCardDescPointsContainer>
        <InfoCardDescPointContainer>
          Get financial security against 15 common risks
        </InfoCardDescPointContainer>
        <InfoCardDescPointContainer>
          Ensure business continuity even if disaster strikes
        </InfoCardDescPointContainer>
        <InfoCardDescPointContainer>
          Get peace of mind to deal with things you can’t control
        </InfoCardDescPointContainer>
      </InfoCardDescPointsContainer>
    )
  } else if (type === "safety") {
    icon = (
      <InfoCardImgContainer>
        <StaticImage 
          src="../../../assets/images/campaigns/why-safety-plan/safety.webp"
          alt="safety-equipments"
          width={191}
          height={190}
        />
      </InfoCardImgContainer>
    )
    desc = (
      <InfoCardDescPointsContainer>
        <InfoCardDescPointContainer>
          Prevent threats from becoming problems
        </InfoCardDescPointContainer>
        <InfoCardDescPointContainer>
          Get a discount on your insurance premium
        </InfoCardDescPointContainer>
        <InfoCardDescPointContainer>
          Make getting insurance claims easier
        </InfoCardDescPointContainer>
      </InfoCardDescPointsContainer>
    )
  }
  
  return (
    <InfoCardContainer mobileBg={mobileBg} fixedWidth={fixedWidth}>
      <OnMobile>
        <SubTitle fontSize="20px" lineHeight="24px" mfontSize="18px" mlineHeight="24px" fontWeight="bold" style={{margin: "20px 0"}}>
          {title}
        </SubTitle>
      </OnMobile>
      {icon}
      <OnDesktop>
        <Text fontSize="20px" lineHeight="24px" mfontSize="18px" mlineHeight="24px" fontWeight="bold" style={{marginTop: "32px", marginBottom: "16px"}}>
          {title}
        </Text>
      </OnDesktop>
      {desc}
    </InfoCardContainer>
  )
}

const WhySafetyInsurance = ({
  handleGetQuoteClick = () => {},
  quoteCtaProps={},
  showGetQuote = false,
  style={},
  mobileStyles={background: "#F6F9FF"},
  desktopStyles={background: "#F6F9FF"},
  mobileItemCardBg = "#fff"
}) => {
  const {label, uppercaseLabel} = quoteCtaProps
  return (
    <Section desktopStyles={desktopStyles} mobileStyles={mobileStyles} style={style}>
      <SectionWrapper>
        <InfoItemsContainer>
          <InfoCard mobileBg={mobileItemCardBg} type="insurance" title="Why does my business need insurance?" />
          <InfoCard mobileBg={mobileItemCardBg} type="safety" title="Why does my business need safety equipment?" />
        </InfoItemsContainer>
        {showGetQuote ? (
          <>
            <OnDesktop><Button type="flatdesign" label={label || "Get a Quote"} uppercaseLabel={uppercaseLabel || false} style={{margin: "auto", marginTop: "98px"}} onClick={handleGetQuoteClick} /></OnDesktop>
            <OnMobile><Button type="flatdesign" label={label || "Get a Quote"} uppercaseLabel={uppercaseLabel || false} style={{margin: "auto", marginTop: "36px", padding: '10px 16px'}} onClick={handleGetQuoteClick} /></OnMobile>
          </>
        ) : null}
      </SectionWrapper>
    </Section>
  )
}

const InfoCardContainer = styled.article`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 20px;
  width: min(520px, 100%);
  background: #FFFFFF;
  box-shadow: 0px 0px 4px #9FA2E2;
  border-radius: 12px;
  /* text-align: center; */
  @media (max-width: 768px) {
    width: ${p => p.fixedWidth ? "184px" : "min(400px, 100%)"};
    min-width: ${p => p.fixedWidth ? "184px" : "auto"};
    background: ${p => p.mobileBg || "#F6F9FF"};
    /* text-align: left; */
    box-shadow: none;
    padding: 0 1rem;
    .icon {
      width: 84px;
    }
  }
`

const InfoCardImgContainer = styled.div`
  width: min(191px, 100%);
`

const InfoCardDescPointsContainer = styled.div`
  /* margin-top: 20px; */
  width: 100%;
`

const InfoCardDescPointContainer = styled.div`
  display: flex;
  gap: 1rem;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  margin: 20px 0;
  color: #333333;
  text-align: left;
  &::before {
    content: '✔';
    color: #57BA70;
  }
`

export const InfoItemsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 4rem;
  margin-top: 60px;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 2rem;
    overflow: auto;
    align-items: center;
    margin-top: 40px;
  }
`

export default WhySafetyInsurance